<template>
  <div class="page-login">
    <login v-show="active === 'login'" @forgot="forgot"></login>
    <forgot v-show="active === 'forgot'" @reset="reset"></forgot>
  </div>
</template>

<script>
import Login from './_login.vue'
import Forgot from './_forgot.vue'
export default {
  components: {
    Login,
    Forgot
  },
  data() {
    return {
      active: 'login'
    }
  },
  created() {
    if (this.$route.query.forgot) {
      this.active = 'forgot'
    }
  },
  methods: {
    // 忘记密码操作: 切换忘记密码设置页面
    forgot() {
      this.active = 'forgot'
    },
    reset() {
      this.active = 'login'
    }
  }
}
</script>

<style lang="scss" scoped>
.page-login {
  height: 100%;
  ::v-deep .y-pro-form {
    .el-form-item {
      position: relative;
      &:not(:last-child) {
        margin-bottom: 48px;
      }
      &:last-child {
        margin-bottom: 32px;
      }
      .el-form-item__label {
        position: absolute;
        left: 0;
        top: 50%;
        line-height: 36px;
        margin-top: -18px;
        z-index: 1;
        pointer-events: none;
        transition: transform 0.2s ease, font-size 0.2s ease;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.4);
      }
      .el-form-item__content {
        line-height: 36px;
      }
      .el-input__inner {
        padding: 0;
        height: 36px;
        line-height: 36px;
      }
    }
    .el-form-item.y-pro-form__item--vertical {
      .el-form-item__label {
        transform: translateY(-28px);
      }
    }
    input {
      border-left: none;
      border-right: none;
      border-top: none;
      border-radius: 0;
    }
    .el-form-item__error {
      width: 100%;
      text-align: right;
    }
  }
  ::v-deep button[type='primary'] {
    height: 48px;
    font-size: 16px;
  }
}
</style>
