<template>
  <div class="_forgot">
    <!-- 忘记密码界面 -->
    <template v-if="active === 'forgot'">
      <page-title>Password Recovery</page-title>
      <div class="_forgot__main">
        <p v-if="sentEmail" class="_forgot__desc--sended">
          Password details have been sent to
          <span class="c-primary">{{ sentEmail }}</span>
          . Please check your mailbox.
        </p>
        <p v-else class="_forgot__desc">
          Enter your account email to reset your password.
        </p>
        <y-pro-form
          ref="formRef"
          :model="model"
          :fields="fields"
          :rules="rules"
          :hide-required-asterisk="true"
          :submitter="{
            submitText: 'Send me a code to reset my password',
            submitButtonProps: {
              loading: $store.state.loading['/overseas/user/requestVerifyCode'],
              style: {
                marginTop: '40px',
                width: '100%'
              }
            },
            resetButtonProps: {
              style: {
                display: 'none'
              }
            }
          }"
          @submit="formSubmit"
        ></y-pro-form>
      </div>
      <p class="_forgot__foot">
        <span>New to YoloLiv ?</span>
        <router-link class="c-primary" to="/user/register">Sign Up</router-link>
      </p>
    </template>
    <!-- /忘记密码界面 -->

    <!-- 验证码界面 -->
    <template v-else-if="active === 'verification-code'">
      <verification-code
        ref="verificationCodeRef"
        @back="verificationCodeBack"
        @resend="verificationCodeResend"
        @submit="verificationCodeSubmit"
      ></verification-code>
    </template>
    <!-- 验证码界面 -->

    <!-- 密码重置界面 -->
    <template v-else-if="active === 'password'">
      <password @submit="passwordSubmit"></password>
    </template>
    <!-- 密码重置界面 -->
  </div>
</template>

<script>
import { emailReg } from '@utils/regex'
import PageTitle from '../components/page-title'
import VerificationCode from '../components/verification-code.vue'
import Password from './_password.vue'

export default {
  components: {
    PageTitle,
    VerificationCode,
    Password
  },
  data() {
    return {
      active: 'forgot',
      sentEmail: '',
      code: '',
      model: {
        email: ''
      },
      fields: [
        {
          type: 'input',
          formItemProps: {
            label: '*Email Address',
            prop: 'email',
            class: ''
          },
          attributes: {
            placeholder: ''
          },
          events: {
            input: (v) => {
              if (v) {
                this.fields[0].formItemProps.class =
                  'y-pro-form__item--vertical'
              }
            },
            focus: () => {
              this.fields[0].formItemProps.class = 'y-pro-form__item--vertical'
            },
            blur: () => {
              if (!this.model.email) {
                this.fields[0].formItemProps.class = ''
              }
            }
          }
        }
      ],
      rules: {
        email: [
          {
            required: true,
            message: 'Email address is required',
            trigger: 'blur'
          },
          {
            validator: (_, value, callback) => {
              if (!value.trim()) {
                callback(new Error('Email address is required'))
                return
              }
              if (!emailReg.test(value)) {
                callback(new Error('Incorrect Email Address'))
                return
              }
              callback()
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    // 输入邮箱发送获取验证码
    formSubmit() {
      const formInstance = this.$refs.formRef.getFormInstance()
      formInstance.validate((valid) => {
        if (!valid) {
          return
        }
        this.requestVerifyCode({
          hideloading: true
        })
      })
    },
    async requestVerifyCode({ hideloading } = {}) {
      const res = await this.$serve.user.requestVerifyCode({
        // codeType 验证码类型：1-注册，2-重置密码
        params: {
          email: this.model.email,
          codeType: 2
        },
        hideloading
      })

      if (res === this.$serve.FAIL) {
        return
      }
      this.sentEmail = this.model.email
      this.active = 'verification-code'
    },
    // 校验验证码是否输入正确
    async verificationCodeSubmit(codeList) {
      const res = await this.$serve.user.verificationCode({
        // codeType 验证码类型：1-注册，2-重置密码
        data: {
          codeType: 2,
          email: this.model.email,
          verfiyCode: codeList.join('')
        },
        hideError: true
      })
      if (res === this.$serve.FAIL) {
        // 校验码错误, 显示错误提示
        this.$refs.verificationCodeRef.setError(true)
        return
      }
      this.code = codeList.join('')
      this.active = 'password'
    },
    // 密码重置
    async passwordSubmit({ password }) {
      const res = await this.$serve.user.reset({
        params: {
          password,
          email: this.model.email,
          code: this.code
        }
      })

      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('Reset Suceess')
      this.$emit('reset')
    },
    // 验证码界面点击底部返回, 清空表单数据和发送邮箱的数据
    verificationCodeBack() {
      this.model.email = ''
      this.sentEmail = ''
      this.active = 'forgot'
    },
    // 重发获取校验码
    verificationCodeResend() {
      this.requestVerifyCode({
        hideloading: false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
._forgot {
  height: 100%;
  display: flex;
  flex-direction: column;
}
._forgot__main {
  margin-top: 40px;
  flex: 1;
}
._forgot__desc--sended,
._forgot__desc {
  line-height: 24px;
  margin-bottom: 48px;
}
.y-pro-form {
  flex: 1;
  ::v-deep input {
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0;
  }
}
._forgot__foot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}
._forgot {
  ::v-deep .el-button {
    margin-top: 12px !important;
  }
}
</style>
