<template>
  <div class="_login">
    <!-- 标题 -->
    <page-title>
      Join Our Beta Program
      <template #sub-title>
        To Build Next Generation Live Streaming Tools
      </template>
    </page-title>
    <!-- /标题 -->

    <!-- 登录表单 -->
    <y-pro-form
      ref="formRef"
      :model="model"
      :fields="fields"
      :hide-required-asterisk="true"
      :rules="rules"
      :submitter="false"
    ></y-pro-form>
    <!-- /登录表单 -->

    <!-- 忘记密码操作 -->
    <p class="_login__forgot c-primary">
      <a @click="forgot">Forgot password?</a>
    </p>
    <!-- /忘记密码操作 -->

    <!-- 登录操作 -->
    <y-button
      style="width: 100%"
      type="primary"
      @click="submit"
      :loading="$store.state.loading['/overseas/user/login']"
    >
      Log In
    </y-button>
    <!-- /登录操作 -->

    <!-- 创建账号提示 -->
    <div class="_login__create">
      <p>Directly log in if you're a current YoloBox/Pro user.</p>
      <p>
        <router-link class="c-primary" to="/user/register">
          Create a new account
        </router-link>
        if you're new to YoloLiv.
      </p>
    </div>
    <!-- /创建账号提示 -->
  </div>
</template>

<script>
import { emailReg, numAndWordReg } from '@utils/regex'
import PageTitle from '../components/page-title'

export default {
  components: {
    PageTitle
  },
  data() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    return {
      model: {
        email: '',
        password: ''
      },
      fields: [
        {
          // 表单邮箱配置
          type: 'input',
          formItemProps: {
            label: '*Email Address',
            prop: 'email',
            class: ''
          },
          attributes: {
            placeholder: '',
            autocomplete: 'new-password'
          },
          events: {
            input: (v) => {
              if (v) {
                this.fields[0].formItemProps.class =
                  'y-pro-form__item--vertical'
              }
            },
            focus: () => {
              this.fields[0].formItemProps.class = 'y-pro-form__item--vertical'
            },
            blur: () => {
              if (!this.model.email) {
                this.fields[0].formItemProps.class = ''
              }
            }
          }
        },
        {
          // 表单密码配置
          type: 'password',
          formItemProps: {
            label: '*Password',
            prop: 'password',
            class: ''
          },
          attributes: {
            placeholder: '',
            autocomplete: 'new-password'
          },
          children: [this.renderPasswordEyesCloase()], // 密码眼睛关闭切换配置, 默认关闭
          events: {
            input: (v) => {
              if (v) {
                this.fields[1].formItemProps.class =
                  'y-pro-form__item--vertical'
              }
            },
            focus: () => {
              this.fields[1].formItemProps.class = 'y-pro-form__item--vertical'
            },
            blur: () => {
              if (!this.model.password) {
                this.fields[1].formItemProps.class = ''
              }
            }
          }
        }
      ],
      rules: {
        // 表单邮箱校验配置
        email: [
          {
            required: true,
            message: 'Email address is required',
            trigger: 'change'
          },
          {
            validator: (_, value, callback) => {
              if (!value.trim()) {
                callback(new Error('Email address is required'))
                return
              }
              if (!emailReg.test(value)) {
                callback('Incorrect Email')
                return
              }
              callback()
            },
            trigger: 'blur'
          }
        ],
        // 表单密码校验配置
        password: [
          {
            required: true,
            message: 'Password is required',
            trigger: 'change'
          },
          {
            validator: (_, value, callback) => {
              if (!value.trim()) {
                callback(new Error('Password is required'))
                return
              }
              if (value.length < 6) {
                callback(
                  new Error('The password should has at least 6 characters')
                )
                return
              }
              if (value.length > 20) {
                callback(new Error('Password cannot exceed 20 characters long'))
                return
              }
              if (!numAndWordReg.test(value)) {
                callback(
                  new Error(
                    'The password should contain at least a letter and a number'
                  )
                )
                return
              }
              callback()
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    // 密码眼睛关闭渲染
    renderPasswordEyesCloase() {
      return {
        slot: 'suffix',
        render: () => {
          return <i class="ui-icon-eyes-close" onClick={this.toggleVisual}></i>
        }
      }
    },
    // 密码眼睛打开渲染
    renderPasswordEyesOpen() {
      return {
        slot: 'suffix',
        render: () => {
          return <i class="ui-icon-eyes-open" onClick={this.toggleVisual}></i>
        }
      }
    },
    // 登录提交
    submit() {
      const formInstance = this.$refs.formRef.getFormInstance()
      formInstance.validate(async (valid) => {
        if (valid) {
          this.$store.dispatch('login', {
            ...this.model,
            // loginSource 登录来源：0-未知，1-box, 2-pc
            loginSource: 2
          })
        }
      })
    },
    // 忘记密码处理
    forgot() {
      this.$emit('forgot')
    },
    // 切换密码可见
    toggleVisual() {
      this.fields[1].type =
        this.fields[1].type === 'password' ? 'type' : 'password'
      this.fields[1].children =
        this.fields[1].type === 'password'
          ? [this.renderPasswordEyesCloase()]
          : [this.renderPasswordEyesOpen()]
    }
  }
}
</script>

<style lang="scss" scoped>
._login {
  ::v-deep .el-input__suffix {
    padding: 0 5px;
    background: #fff;
    top: -1px;
    right: 0;
  }
  ::v-deep .ui-icon-eyes-open,
  ::v-deep .ui-icon-eyes-close {
    margin-top: 6px;
  }
}
.y-pro-form {
  margin-top: 56px;
}
._login__forgot {
  margin-bottom: 8px;
  line-height: 24px;
  font-size: 14px;
  text-align: right;
  a {
    cursor: pointer;
  }
}
._login__create {
  margin-top: 32px;
  line-height: 20px;
  text-align: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  p + p {
    margin-top: 4px;
  }
  a {
    text-decoration: underline;
    display: inline-block;
    margin-right: 4px;
  }
}

@media screen and (max-width: 768px) {
  ._login__create a {
    display: block;
  }
}
</style>
