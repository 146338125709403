var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"_forgot"},[(_vm.active === 'forgot')?[_c('page-title',[_vm._v("Password Recovery")]),_c('div',{staticClass:"_forgot__main"},[(_vm.sentEmail)?_c('p',{staticClass:"_forgot__desc--sended"},[_vm._v(" Password details have been sent to "),_c('span',{staticClass:"c-primary"},[_vm._v(_vm._s(_vm.sentEmail))]),_vm._v(" . Please check your mailbox. ")]):_c('p',{staticClass:"_forgot__desc"},[_vm._v(" Enter your account email to reset your password. ")]),_c('y-pro-form',{ref:"formRef",attrs:{"model":_vm.model,"fields":_vm.fields,"rules":_vm.rules,"hide-required-asterisk":true,"submitter":{
          submitText: 'Send me a code to reset my password',
          submitButtonProps: {
            loading: _vm.$store.state.loading['/overseas/user/requestVerifyCode'],
            style: {
              marginTop: '40px',
              width: '100%'
            }
          },
          resetButtonProps: {
            style: {
              display: 'none'
            }
          }
        }},on:{"submit":_vm.formSubmit}})],1),_c('p',{staticClass:"_forgot__foot"},[_c('span',[_vm._v("New to YoloLiv ?")]),_c('router-link',{staticClass:"c-primary",attrs:{"to":"/user/register"}},[_vm._v("Sign Up")])],1)]:(_vm.active === 'verification-code')?[_c('verification-code',{ref:"verificationCodeRef",on:{"back":_vm.verificationCodeBack,"resend":_vm.verificationCodeResend,"submit":_vm.verificationCodeSubmit}})]:(_vm.active === 'password')?[_c('password',{on:{"submit":_vm.passwordSubmit}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }