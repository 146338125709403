var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"_password"},[_c('page-title',[_vm._v("Set Password")]),_c('div',{staticClass:"_password__main"},[_c('p',{staticClass:"_password__desc"},[_vm._v(" Your email has been autheuticated,please set a new password. ")]),_c('y-pro-form',{ref:"formRef",attrs:{"model":_vm.model,"fields":_vm.fields,"rules":_vm.rules,"hide-required-asterisk":true,"submitter":{
        submitText: 'Submit',
        submitButtonProps: {
          loading: _vm.$store.state.loading['/overseas/user/reset'],
          style: {
            marginTop: '40px',
            width: '100%'
          }
        },
        resetButtonProps: {
          style: {
            display: 'none'
          }
        }
      }},on:{"submit":_vm.submit}})],1),_c('p',{staticClass:"_password__foot"},[_c('span',[_vm._v("New to YoloLiv ?")]),_c('router-link',{staticClass:"c-primary",attrs:{"to":"/user/register"}},[_vm._v("Sign Up")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }