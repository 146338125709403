import { render, staticRenderFns } from "./_forgot.vue?vue&type=template&id=57d27b63&scoped=true&"
import script from "./_forgot.vue?vue&type=script&lang=js&"
export * from "./_forgot.vue?vue&type=script&lang=js&"
import style0 from "./_forgot.vue?vue&type=style&index=0&id=57d27b63&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57d27b63",
  null
  
)

export default component.exports