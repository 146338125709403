<template>
  <div class="_password">
    <page-title>Set Password</page-title>
    <div class="_password__main">
      <p class="_password__desc">
        Your email has been autheuticated,please set a new password.
      </p>
      <y-pro-form
        ref="formRef"
        :model="model"
        :fields="fields"
        :rules="rules"
        :hide-required-asterisk="true"
        :submitter="{
          submitText: 'Submit',
          submitButtonProps: {
            loading: $store.state.loading['/overseas/user/reset'],
            style: {
              marginTop: '40px',
              width: '100%'
            }
          },
          resetButtonProps: {
            style: {
              display: 'none'
            }
          }
        }"
        @submit="submit"
      ></y-pro-form>
    </div>
    <p class="_password__foot">
      <span>New to YoloLiv ?</span>
      <router-link class="c-primary" to="/user/register">Sign Up</router-link>
    </p>
  </div>
</template>

<script>
import { numAndWordReg } from '@utils/regex'
import PageTitle from '../components/page-title'
export default {
  components: {
    PageTitle
  },
  data() {
    return {
      model: {
        password: '',
        confirmPassword: ''
      },
      fields: [
        {
          type: 'password',
          formItemProps: {
            label: '*Password',
            prop: 'password',
            class: ''
          },
          attributes: {
            placeholder: ''
          },
          events: {
            input: (v) => {
              if (v) {
                this.fields[0].formItemProps.class =
                  'y-pro-form__item--vertical'
              }
            },
            focus: () => {
              this.fields[0].formItemProps.class = 'y-pro-form__item--vertical'
            },
            blur: () => {
              if (!this.model.password) {
                this.fields[0].formItemProps.class = ''
              }
            }
          }
        },
        {
          type: 'password',
          formItemProps: {
            label: '*Confirm Password',
            prop: 'confirmPassword',
            class: ''
          },
          attributes: {
            placeholder: ''
          },
          events: {
            input: (v) => {
              if (v) {
                this.fields[1].formItemProps.class =
                  'y-pro-form__item--vertical'
              }
            },
            focus: () => {
              this.fields[1].formItemProps.class = 'y-pro-form__item--vertical'
            },
            blur: () => {
              if (!this.model.confirmPassword) {
                this.fields[1].formItemProps.class = ''
              }
            }
          }
        }
      ],
      rules: {
        password: [
          {
            required: true,
            message: 'Password is required',
            trigger: 'change'
          },
          {
            validator: (_, value, callback) => {
              if (!value.trim()) {
                callback(new Error('Password is required'))
                return
              }
              if (value.length < 6) {
                callback(
                  new Error('The password should has at least 6 characters')
                )
                return
              }
              if (value.length > 20) {
                callback(new Error('Password cannot exceed 20 characters long'))
                return
              }
              if (!numAndWordReg.test(value)) {
                callback(
                  new Error(
                    'The password should contain at least a letter and a number'
                  )
                )
                return
              }
              callback()
            },

            trigger: 'blur'
          }
        ],
        confirmPassword: [
          {
            required: true,
            message: 'Password is required',
            trigger: 'change'
          },
          {
            validator: (_, value, callback) => {
              if (!value.trim()) {
                callback(new Error('Password is required'))
                return
              }
              if (value.length < 6) {
                callback(
                  new Error('The password should has at least 6 characters')
                )
                return
              }
              if (value.length > 20) {
                callback(new Error('Password cannot exceed 20 characters long'))
                return
              }
              if (!numAndWordReg.test(value)) {
                callback(
                  new Error(
                    'The password should contain at least a letter and a number'
                  )
                )
                return
              }
              if (this.model.password && value !== this.model.password) {
                callback(new Error("Those passwords didn't match. Try again."))
                return
              }
              callback()
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    submit() {
      this.$emit('submit', this.model)
    },
    getData() {
      return this.model
    }
  }
}
</script>

<style lang="scss" scoped>
._password {
  height: 100%;
  display: flex;
  flex-direction: column;
}
._password__main {
  margin-top: 40px;
  flex: 1;
}
._password__desc {
  line-height: 1.5;
  margin-bottom: 48px;
}
._password__foot {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
